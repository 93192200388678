<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Route Manager
      <router-link slot="right" to="/" class="button">
        Dashboard
      </router-link>
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <em>
          <p class="pb-2">Manage routes deployed on the Apache Camel router in the Core Container to orchestrate data flows for ingress and egress of the Connector.</p>
          <p>In principle, routes are pointing to data apps, instead of the routes for selfdescription and artifacts that are handled locally within the Core Container.</p>
        </em>
      </card-component>
      <card-component
        title="Camel Routes"
        class="has-table has-mobile-sort-spaced"
        icon="routes"
        headerColor="info"
      >
        <b-table
          :loading="isLoading"
          :paginated="paginated"
          :per-page="15"
          :striped="true"
          :hoverable="true"
          default-sort="name"
          :data="routes"
        >
          <b-table-column label="Route ID" field="id" sortable v-slot="props">
            {{ props.row.id }}
          </b-table-column>
          <b-table-column label="Uptime" field="uptime" sortable v-slot="props">
            {{ formatUptime(props.row.uptime) }}
          </b-table-column>
          <b-table-column label="Status" field="status" sortable v-slot="props">
            {{ props.row.status }}
          </b-table-column>
          <b-table-column label="Processed" field="processed" sortable v-slot="props">
            <span class="has-text-success">
              <strong class="has-text-success">
                {{ props.row.stats.processed }}
              </strong>
              messages
            </span>
          </b-table-column>
          <b-table-column label="Failed" field="failed" sortable v-slot="props">
            <span class="has-text-danger">
              <strong class="has-text-danger">
                {{ props.row.stats.failed }}
              </strong>
              messages
            </span>
          </b-table-column>
          <b-table-column label="Mean (min / max)" field="mean" sortable v-slot="props">
            {{ props.row.stats.mean }}ms ({{ props.row.stats.min }}ms / {{ props.row.stats.max }}ms)
          </b-table-column>
          <b-table-column label="Actions" v-slot="props">
            <b-icon
              icon="delete"
              type="is-danger"
              class="is-clickable"
              @click.native="deleteRoute(props.row.id)"
            >
            </b-icon>
            <b-icon
              icon="file-search"
              type="is-info"
              class="is-clickable"
              @click.native="showRouteModal(props.row.id)"
            >
            </b-icon>
          </b-table-column>
          <section slot="empty" class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No routes present...</p>
            </div>
          </section>
        </b-table>
      </card-component>
      <card-component title="Insert new route" icon="file-document-edit" headerColor="info" class="tile is-child">
        <form @submit.prevent="insertRoute">
          <b-field label="Route XML" horizontal>
            <b-radio v-model="newRoute.type"
                name="type"
                native-value="httpIngress">
                HTTPS Ingress
            </b-radio>
            <b-radio v-model="newRoute.type"
                name="type"
                native-value="httpEgress">
                HTTPS Egress
            </b-radio>
            <b-radio v-model="newRoute.type"
                name="type"
                native-value="idscpIngress">
                IDSCP Ingress
            </b-radio>
            <b-radio v-model="newRoute.type"
                name="type"
                native-value="idscpEgress">
                IDSCP Egress
            </b-radio>
          </b-field>
          <b-field label="Route ID" horizontal>
            <b-input v-model="newRoute.id"
                placeholder="Route ID (optional)" />
          </b-field>
          <template v-if="newRoute.type === 'httpIngress'">
            <b-field label="External port" horizontal>
              <b-input v-model="newRoute.httpIngress.port"
                  placeholder="8080"
                  type="number" />
            </b-field>
            <b-field label="Endpoint" message="External endpoint" horizontal>
              <b-input v-model="newRoute.httpIngress.endpoint"
                  placeholder="" />
            </b-field>
            <b-field v-if="detailedForm" label="Parameters" message="Apache Camel HTTP Component parameters" horizontal>
              <b-input v-model="newRoute.httpIngress.parameters"
                  placeholder="" />
            </b-field>
            <b-field label="Data App Endpoint" message="Local endpoint in the Data App" horizontal>
              <b-input v-model="newRoute.httpIngress.dataApp"
                  placeholder=""
                  required />
            </b-field>
            <b-field label="Clearing" message="Requires Clearing information set on Core Container level" horizontal>
              <b-checkbox v-model="newRoute.httpIngress.clearing">
                Enabled
              </b-checkbox>
            </b-field>
            <b-field v-if="detailedForm" label="DAPS Verification" horizontal>
              <b-checkbox v-model="newRoute.httpIngress.dapsVerify">
                Enabled
              </b-checkbox>
            </b-field>
          </template>
          <template v-else-if="newRoute.type === 'httpEgress'">
            <b-field label="Internal port" horizontal>
              <b-input v-model="newRoute.httpEgress.listenPort"
                  placeholder="8080"
                  type="number" />
            </b-field>
            <b-field label="Endpoint" message="Internal endpoint" horizontal>
              <b-input v-model="newRoute.httpEgress.endpoint"
                  placeholder="" />
            </b-field>
            <b-field v-if="detailedForm" label="Forward Header" message="HTTP Header containing information to which connector the request should be forwarded" horizontal>
              <b-input v-model="newRoute.httpEgress.forwardHeader"
                  placeholder="" />
            </b-field>
            <b-field label="Clearing" message="Requires Clearing information set on Core Container level" horizontal>
              <b-checkbox v-model="newRoute.httpEgress.clearing">
                Enabled
              </b-checkbox>
            </b-field>
            <b-field v-if="detailedForm" label="DAPS Injection" horizontal>
              <b-checkbox v-model="newRoute.httpEgress.dapsInject">
                Enabled
              </b-checkbox>
            </b-field>
          </template>
          <template v-else-if="newRoute.type === 'idscpIngress'">
            <b-field label="External port" horizontal>
              <b-input v-model="newRoute.idscpIngress.port"
                  placeholder="9292"
                  type="number" />
            </b-field>
            <b-field label="Endpoint" message="External endpoint" horizontal>
              <b-input v-model="newRoute.idscpIngress.endpoint"
                  placeholder="" />
            </b-field>
            <b-field label="Attestation Level" horizontal>
              <b-input v-model="newRoute.idscpIngress.attestation"
                  type="number"
                  placeholder="" />
            </b-field>
            <b-field label="Data App Endpoint" message="Local endpoint in the Data App" horizontal>
              <b-input v-model="newRoute.idscpIngress.dataApp"
                  placeholder=""
                  required />
            </b-field>
            <b-field label="Clearing" message="Requires Clearing information set on Core Container level" horizontal>
              <b-checkbox v-model="newRoute.idscpIngress.clearing">
                Enabled
              </b-checkbox>
            </b-field>
            <b-field v-if="detailedForm" label="DAPS Verification" horizontal>
              <b-checkbox v-model="newRoute.idscpIngress.dapsVerify">
                Enabled
              </b-checkbox>
            </b-field>
          </template>
          <template v-else-if="newRoute.type === 'idscpEgress'">
            <b-field label="Internal port" horizontal>
              <b-input v-model="newRoute.idscpEgress.listenPort"
                  placeholder="8080"
                  type="number" />
            </b-field>
            <b-field label="Endpoint" message="Internal endpoint" horizontal>
              <b-input v-model="newRoute.idscpEgress.endpoint"
                  placeholder="" />
            </b-field>
            <b-field label="Attestation Level" horizontal>
              <b-input v-model="newRoute.idscpEgress.attestation"
                  type="number"
                  placeholder="" />
            </b-field>
            <b-field v-if="detailedForm" label="Forward Header" message="HTTP Header containing information to which connector the request should be forwarded" horizontal>
              <b-input v-model="newRoute.idscpEgress.forwardHeader"
                  placeholder="" />
            </b-field>
            <b-field label="Clearing" message="Requires Clearing information set on Core Container level" horizontal>
              <b-checkbox v-model="newRoute.idscpEgress.clearing">
                Enabled
              </b-checkbox>
            </b-field>
            <b-field v-if="detailedForm" label="DAPS Injection" horizontal>
              <b-checkbox v-model="newRoute.idscpEgress.dapsInject">
                Enabled
              </b-checkbox>
            </b-field>
          </template>
          <b-field v-if="detailedForm" label="Pre-processing" message="Newline seperated Camel Processors or HTTP adresses" horizontal>
            <b-input
              v-model="newRoute.preProcessing"
              type="textarea"
              placeholder="Camel Processor (optional)"
              rows="2"
            />
          </b-field>
          <b-field v-if="detailedForm" label="Post-processing" message="Newline seperated Camel Processors or HTTP adresses" horizontal>
            <b-input
              v-model="newRoute.postprocessing"
              type="textarea"
              placeholder="Camel Processor (optional)"
              rows="2"
            />
          </b-field>
          <hr />
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary"
                  >Insert route</b-button
                >
              </div>
              <div class="control">
                <b-button type="is-primary is-outlined" @click="()=>{newRoute.xml = ''}"
                  >Reset</b-button
                >
              </div>
              <div class="control p-3">
                <b-checkbox v-model="detailedForm">
                  Show all configurable properties
                </b-checkbox>
              </div>
            </b-field>
          </b-field>
        </form>
      </card-component>
      <b-modal :active.sync="routeModal.isActive" has-modal-card>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">Route</p>
          </header>
          <section class="modal-card-body" v-if="routeModal.route">
            <b-field label="Route ID" horizontal>
              <p class="control">
                  {{ routeModal.route.id }}
              </p>
            </b-field>
            <b-field label="Uptime" horizontal>
              <p class="control">
                  {{ formatUptime(routeModal.route.uptime) }} (<em>{{ formatStartTime(routeModal.route.start) }}</em>)
              </p>
            </b-field>
            <b-field label="Status" horizontal>
              <p class="control">
                  {{ routeModal.route.status }}
              </p>
            </b-field>
            <b-field label="Processed" horizontal>
              <p class="control has-text-success">
                  {{ routeModal.route.stats.processed }} messages
              </p>
            </b-field>
            <b-field label="Failed" horizontal>
              <p class="control has-text-danger">
                  {{ routeModal.route.stats.failed }} messages
              </p>
            </b-field>
            <b-field label="Inflight" horizontal>
              <p class="control has-text-info">
                  {{ routeModal.route.stats.inflight }} messages
              </p>
            </b-field>
            <b-field label="Mean" horizontal>
              <p class="control">
                  {{ routeModal.route.stats.mean }}ms
              </p>
            </b-field>
            <b-field label="Min" horizontal>
              <p class="control">
                  {{ routeModal.route.stats.min }}ms
              </p>
            </b-field>
            <b-field label="Max" horizontal>
              <p class="control">
                  {{ routeModal.route.stats.max }}ms
              </p>
            </b-field>
            <b-field label="Route XML" horizontal>
              <pre class="control"><code>{{ routeModal.route.content.replace(/^\s*\n/gm, "") }}</code></pre>
            </b-field>
          </section>
          <footer class="modal-card-foot">
              <button class="button" type="button" @click="routeModal.isActive = false">Close</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import { mapState } from 'vuex'
import moment from 'moment-timezone'

export default {
  name: 'Routes',
  components: {
    HeroBar,
    CardComponent,
    TitleBar
  },
  data () {
    return {
      isLoading: false,
      paginated: true,
      detailedForm: false,
      routes: [],
      offers: [],
      routeModal: {
        isActive: false,
        route: null
      },
      newRoute: {
        type: 'httpIngress',
        id: null,
        preProcessing: null,
        postprocessing: null,
        httpIngress: {
          port: 8080,
          endpoint: null,
          parameters: null,
          dataApp: null,
          clearing: false,
          dapsVerify: true
        },
        httpEgress: {
          listenPort: 8080,
          endpoint: null,
          forwardHeader: 'Forward-To',
          clearing: false,
          dapsInject: true
        },
        idscpIngress: {
          port: 9292,
          endpoint: null,
          attestation: 1,
          dataApp: null,
          clearing: false,
          dapsVerify: true
        },
        idscpEgress: {
          listenPort: 8080,
          endpoint: null,
          attestation: 1,
          forwardHeader: 'Forward-To',
          clearing: false,
          dapsInject: true
        }
      }
    }
  },
  computed: {
    titleStack () {
      return ['Routes', 'Route Manager']
    },
    ...mapState(['api'])
  },
  async created () {
    this.routeInterval = setInterval(this.getRoutes, 30000)
    await this.getRoutes()
  },
  beforeDestroy () {
    clearInterval(this.routeInterval)
  },
  methods: {
    async getRoutes () {
      try {
        const routes = await this.api.get('/routes')
        this.routes = routes.data
      } catch (e) {
        clearInterval(this.routeInterval)
        this.$buefy.snackbar.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 10000,
          queue: false,
          cancelText: 'OK',
          actionText: 'Retry',
          onAction: () => {
            clearInterval(this.routeInterval)
            this.getRoutes()
            this.routeInterval = setInterval(this.getRoutes, 30000)
          }
        })
        console.log(e)
      }
    },
    async insertRoute () {
      try {
        await this.api.post('/routes', {
          type: this.newRoute.type,
          id: this.newRoute.id,
          preProcessing: this.newRoute.preProcessing,
          postprocessing: this.newRoute.postprocessing,
          ...this.newRoute[this.newRoute.type]
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        this.$buefy.toast.open({
          message: 'Successfully inserted the route',
          type: 'is-success',
          position: 'is-bottom'
        })
        this.newRoute.xml = null
        this.getRoutes()
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    },
    async deleteRoute (routeId) {
      this.$buefy.dialog.confirm({
        title: 'Delete route',
        message: `Are you sure you want to permanently delete the route ${routeId}?`,
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await this.api.delete(
              `/routes/${encodeURIComponent(routeId)}`
            )
            await this.getRoutes()
          } catch (e) {
            this.$buefy.toast.open({
              message: `Error: ${e.message}`,
              type: 'is-danger',
              position: 'is-bottom'
            })
            console.log(e)
          }
        }
      })
    },
    async showRouteModal (routeId) {
      try {
        const route = await this.api.get(`/routes/${encodeURIComponent(routeId)}`)
        this.routeModal = {
          isActive: true,
          route: route.data
        }
        this.routeModal.route.start = moment().valueOf() - route.data.uptime
      } catch (e) {
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger',
          position: 'is-bottom'
        })
        console.log(e)
      }
    },
    formatUptime (uptimeMillis) {
      if (uptimeMillis) {
        return moment.duration(uptimeMillis, 'milliseconds').humanize()
      } else {
        return null
      }
    },
    formatStartTime (timestamp) {
      if (timestamp) {
        return moment(timestamp).format('DD-MM-YYYY HH:mm:ss z')
      } else {
        return null
      }
    }
  }
}
</script>
